.footer {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.footer__img-social {
  display: flex;
  flex-direction: row;
}

.footer__img-social__img {
  float: left;
  padding-left: 30px;
}

.footer__img-social__img__img {
  height: 100px;
}

.footer__img-social__img__text {
  text-align: center;
  opacity: 0.7;
  line-height: 12px;
  font-size: 10px;
  width: 195px;
  height: 110px;
  position: absolute;
}

.footer__img-social__social {
  padding-right: 25px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  gap: 1%;
}

.footer__img-social_div {
  position: relative;
  width: 58px;
}

.footer_img-social_social_hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 5%;
  bottom: 35%;
  opacity: 0;
  margin: auto;
  transition: opacity 0s;
}

.footer__img-social__social a:hover .footer_img-social_social_hover {
  transform: scale(1.2);
  opacity: 1;
}

.footer__img-social__social img {
  height: 55.5px;
  width: 100%;
}

.footer__img_div {
  height: 100%;
  width: 100%;
}

.footer__img {
  width: 99%;
  margin-left: 1%;
  margin-top: -1.5%;
}

.footer_rights {
  position: absolute;
  margin: auto;
  top: 74%;
  bottom: 0;
  left: 94%;
  right: 0;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 350;
  font-size: 14.5236px;
  line-height: 17px;
  display: flex;
  color: #000000;
  transform: rotate(89.35deg);
  width: max-content;
  height: max-content;
}
