.charity_saleh {
  text-align: center;
  height: 10%;
}

.charity_img-vid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 150px;
  margin-bottom: 50px;
}

.charity_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.charity_img img {
  width: 235.39px;
  height: 106px;
}

.charity_img_link {
  text-align: right;
  color: black;
  text-decoration-line: none;
}

.charity_img_nav-link:link {
  text-decoration: none;
  height: fit-content;
}
