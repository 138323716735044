.nav-body {
  margin-top: 0px;
  position: relative;
}

.nav-body__icons {
  padding-top: 50px;
  gap: 1rem;
  padding-left: auto;
  padding-right: auto;
}

.nav-body__icons__list {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.nav-body__icons__list__div {
  position: relative;
  height: 80px;
  max-width: 100%;
}

.nav-body__icons__list__div__img {
  height: 80px;
}

.nav-body__icons__list__div__hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  height: 80px;
  transition: opacity 0s;
}

.nav-body__icons__list li:hover .nav-body__icons__list__div__hover {
  /* background-color: black; */
  transform: scale(1.2);
  opacity: 1;
}

.nav-body__icons__list li a.active .nav-body__icons__list__div__hover {
  /* background-color: black; */
  transform: scale(1.2);
  opacity: 1;
}
