.token-page {
  display: flex;
  flex-direction: column;
}

.token-page::after {
  content: '';
  background-image: url('./../Assets/Gif/Background_gif.gif');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
  opacity: 0.05;
}

.token-page_bgd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.token-page_content {
  position: absolute;
  text-align: center;
  width: 100%;
}
