.buy-token {
  background-size: cover;
  isolation: isolate;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
}

.buy-token::after {
  content: '';
  background-image: url('./../Assets/Gif/Background_gif.gif');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
  opacity: 0.05;
}

.buy-token_center {
  text-align: center;
}

.buy-token_start {
  width: 100%;
  text-align: center;
  padding-top: 6%;
}

.buy-token_point-2 {
  width: 100%;
  text-align: center;
  padding-bottom: 2%;
}

.buy-token__video {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.buy-token__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 12%;
  right: 1%;
  margin: auto;
  border: 3px solid white;
}
.buy-token__video__img {
  object-fit: cover;
}

.buy-token_point-3 {
  width: 100%;
  text-align: center;
  padding-right: 0.5%;
  padding-top: 2%;
}

.buy-token_cex-dex {
  display: flex;
  flex-direction: row;
  gap: 10%;
}

.buy-token_dex {
  position: relative;
  margin-left: 34%;
}

.buy-token_cex {
  position: relative;
}

.buy-token_dex_a {
  width: 120%;
  display: block;
  font-family: '"Avenir", sans-serif';
  text-decoration: none;
  color: #ffffff;
  background-color: grey;
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 27px;
}

.buy-token_dex_a:hover {
  background-color: rgb(83, 81, 81);
  color: #ffffff;
}

.buy-token_dex_img {
  width: 100%;
}
/* style={{ margin: 'auto', height: '17%', width: '25.6%' }} */
