.home-page {
  /* --bg-height: 2400px; */
  --social-height: 30px;
  --social-height-monkey: 40px;
  --social-height-monkey-mask: 50px;
  --social-height-boat: 70px;
  --social-height-blur: 25px;
  background-size: cover;
  position: absolute;
  isolation: isolate;
  width: 100%;
  min-height: 100%;
}

/* .home-page::after {
  content: '';
  background-image: url('./../Assets/Gif/Background_gif.gif');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
  opacity: 0.05;
  min-height: 100%;
} */

.home-page__title {
  font-size: 100px;
  padding-top: 50px;
  margin-top: -30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Avenir';
  text-decoration-color: #000000;
  font-size: 130%;
  padding-bottom: 3%;
}

.home-page__title a {
  color: black;
  text-decoration: none;
}

.home-page__title a:hover {
  color: black;
}

.home-page__title img {
  height: 100px;
}

.home-page_social_logo {
  width: 1263.07px;
  display: flex;
  flex-direction: row;
  margin-top: -50px;
  margin-right: auto;
  margin-left: auto;
}

.home-page_social_logo_inspression {
  width: 786.453px;
  display: flex;
  justify-content: flex-end;
}

.home-page_social_logo_inspression img {
  width: 154px;
  height: 37.94px;
}

.home-page_social_logo_inspression_hover {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin-right: 90px;
  position: relative;
}

.home-page_social_logo_inspression_hover:hover
  .home-page_social_logo_inspression_img_no_hover {
  opacity: 1;
}

.home-page_social_logo_inspression_img_no_hover {
  position: absolute;
  right: 0px;
  opacity: 0;
}

.home-page__social {
  display: flex;
  width: 476.617px;
  margin-right: -10px;
  height: 50px;
}

.home-page__social__col {
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  opacity: 0.2;
  position: relative;
}

.home-page__social__col_btn {
  border: none;
  background-color: transparent;
  height: fit-content;
}

.home-page__social__col__boatdiv {
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  float: left;
  opacity: 0.2;
  margin-top: -10px;
}

.home-page__social__col__boat {
  height: var(--social-height-boat);
  padding-bottom: 20px;
}

.home-page__social__col__blur {
  height: var(--social-height-blur);
  margin-top: 10%;
}

.home-page__social__col__me {
  height: var(--social-height);
}

.home-page__social__col__twitter {
  height: var(--social-height);
}

.home-page__social__col__discord {
  height: var(--social-height);
}

.home-page__social__col__monkey {
  height: var(--social-height-monkey);
  position: absolute;
  top: 0;
  bottom: 50%;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}

.home-page__social__col__monkey-mask {
  height: var(--social-height-monkey-mask);
  position: absolute;
  top: 0;
  bottom: 35%;
  left: -70%;
  right: 0;
  margin: auto;
  opacity: 1;
}

.home-page__social__col:hover {
  opacity: 1;
}

.home-page__social__col__boatdiv:hover {
  opacity: 1;
}

.home-page__social::after {
  content: '';
  clear: both;
  display: table;
}

.home-page__group1 {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.home-page__group1__art {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 790px;
  margin-top: -0.6%;
}

.home-page__rights {
  /* top: 1000px;
  bottom: 0;
  right: 0;
  left: 0; */
  text-align: center;
  font-weight: medium;
  opacity: 0.8;
  font-size: 15px;
  line-height: 30px;
  padding-top: 5%;
}
