.bulletin_image {
  width: 21%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Avenir';
  font-style: normal;
  color: #474747;
}

.bulletin_image_img img {
  width: 100%;
}
