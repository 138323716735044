.bulletin {
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}

.bulletin_head {
  text-align: center;
  padding-top: 2%;
}

.bulletin_content {
  position: absolute;
  height: 55%;
  width: 100%;
  margin: auto;
  padding-top: 2%;
}

.bulletin_nft {
  background: #e2e2e2;
  border: 0.906158px solid #e2e2e2;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 55%;
  margin: auto;
  overflow: auto;
}

.bulletin_nft_inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  margin-top: 5%;
}

.bulletin_btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: -1%;
  gap: 1%;
}

.bulletin_meme {
  background: #e2e2e2;
  border: 0.906158px solid #e2e2e2;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 55%;
  margin: auto;
  overflow: auto;
}

.bulletin_meme_inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  margin-top: 5%;
}

.bulletin_story {
  background: #ffffff;
  border: 0.906158px solid #e2e2e2;
  height: 100%;
  width: 70%;
  margin: auto;
  display: flex;
}

.bulletin_story_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 5%;
}

.bulletin_story_row_text {
  width: 80%;
  display: flex;
  flex-direction: column;
  font-family: 'Avenir', sans-serif;
  color: #474747;
}

.bulletin_story_r1_creator {
  text-align: right;
}

.bulletin_story_margin {
  margin-top: 5%;
  overflow: auto;
  gap: 3%;
  display: flex;
  flex-direction: column;
}
