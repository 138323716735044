.buy-nft-middle_offers {
  margin-top: 100px;
  width: 100%;
}

.buy-nft-middle_mint {
  text-align: center;
  margin-right: 200px;
}

.buy-nft-middle_banner {
  margin-top: 80px;
  width: 100%;
}
.buy-nft-middle_bannerr { 
 
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
 
}
.buy-nft-middle_offers_txt {
  text-align: center;
}

.wled {
  float: left;
  width: 300px;
 scale: 1.2;
  padding: 10px;
  margin-left: -30px;
}