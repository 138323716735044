@font-face {
  font-family: 'Avenir';
  src: url('./Styles/Avenir LT Std 35 Light.otf');
}

@font-face {
  font-family: 'Sweet Home Oklahoma';
  src: url('./Styles/Sweet Home Oklahoma.ttf');
}

.font_avenir {
  font-family: 'Avenir';
}

.font_oklahoma {
  font-family: 'Sweet Home Oklahoma';
}