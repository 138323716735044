.club-page {
  background-size: cover;
  position: relative;
  isolation: isolate;
  height: var(--bg-height);
  width: 100%;
}

.club-page::after {
  content: '';
  background-image: url('./../Assets/Gif/Background_gif.gif');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
  opacity: 0.05;
  height: var(--bg-height);
}

.club-page_blog {
  text-align: center;
}

.club-page_blogs {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 50px;
}

.club-page_blogs_indi {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.club-page_blogs_indi_c1 img {
  width: 443px;
  height: 266px;
}

.club-page_vlog {
  margin-top: 70px;
  text-align: center;
}

.club-page_vlogs {
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.club-page_vlogs_indi {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.club-page_vlogs_indi_txt {
  text-align: left;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 27px;
  color: #474747;
}

.club-page_vlogs_indi_img {
  width: 340px;
  height: 186px;
}
