.ai-nft {
  width: 256px;
  padding-bottom: 5%;
}

.ai-nft_image {
  width: 256px;
  height: 256px;
  margin-right: auto;
  margin-left: auto;
  border:black solid 1px;

}

.ai-nft_number {
  margin-top: 13px;
  font-family: 'Avenir';
  font-size: 20px;
  text-align: center;
  color: #474747;
  line-height: 10px;
}

.ai-nft_desc {
  font-family: 'Avenir';
  font-size: 15px;
  text-align: center;
  color: #474747;
  line-height: 10px;
}


