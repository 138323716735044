.how {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.how_build_desc {
  font-family: 'Sweet Home Oklahoma';
  font-style: normal;
  font-size: 38px;
  text-align: center;
  color: #000000;
}

.how_back_btn {
  width: 201px;
  height: 39px;
  background: rgba(101, 101, 101, 0.5);
  margin-top: 30px;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.how_back_btn:hover {
  background: #656565;
}
