

.pop-up_inner_btn {
  position: absolute;
  top: 1%;
  right: 1%;
  margin: auto;
  width: 6px;
  height: 6px;
  cursor: pointer;
}
