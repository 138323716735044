.inspression {
  --rotate-img-height: 255.34px;
  --rotate-img-width: 255.34px;

  height: 100%;
  width: 100%;
}

.inspression_build {
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspression_build_desc {
  font-family: 'Sweet Home Oklahoma';
  font-style: normal;
  font-size: 38px;
  text-align: center;
  color: #000000;
}

.inspression_bar_book_drop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 121px;
}

.inspression_bar_book {
  display: flex;
  flex-direction: column;
}

.inspression_bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inspression_bar_tile_rotate {
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;
}

.inspression_bar_tile_rotate p {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin-bottom: 10px;
}

/* .inspression_tile_text_underline {
  height: 0px;
  border: 2px solid #545454;
  border-radius: 10px;
  margin-top: -15px;
} */

/* .inspression_rotate_text_underline {
  height: 0px;
  border: 2px solid #545454;
  border-radius: 10px;
  margin-top: -15px;
} */

.inspression_rotate_book {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
}

.inspression_rotate_book_img {
  position: relative;
  margin: auto;
  margin-top: 25px;
  width: 600px;
  height: 300px;
}

.inspression_rotate_book_img_ori {
  position: absolute;
  width: var(--rotate-img-width);
  height: var(--rotate-img-height);
  transition: transform 2s, z-index 1s;
  z-index: 4;
  margin: auto;
  top: 0px;
  right: 0px;
  left: 0;
  bottom: 0px;
  border: #bebdbd solid 1px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.inspression_rotate_book_img_ori_nft {
  width: 167px;
  height: 155.36px;
  margin-top: 5px;
}

.inspression_rotate_book_img_ani {
  width: var(--rotate-img-width);
  height: var(--rotate-img-height);
  transition: transform 2s, z-index 1s;
  position: absolute;
  z-index: 3;
  margin: auto;
  top: 0;
  right: 0;
  left: 300px;
  bottom: 40px;
  border: #bebdbd solid 1px;
  cursor: pointer;
}

.inspression_rotate_book_img_new {
  width: var(--rotate-img-width);
  height: var(--rotate-img-height);
  transition: transform 2s, z-index 1s;
  position: absolute;
  z-index: 2;
  margin: auto;
  top: 0;
  right: 300px;
  left: 0;
  bottom: 40px;
  border: #bebdbd solid 1px;
  cursor: pointer;
}

.inspression_rotate_book_img_meme {
  width: var(--rotate-img-width);
  height: var(--rotate-img-height);
  transition: transform 2s, z-index 1s;
  position: absolute;
  z-index: 1;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 80px;
  border: #bebdbd solid 1px;
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.inspression_rotate_book_img_meme p {
  font-family: 'Sweet Home Oklahoma';
  font-size: 50px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 30px;
}

.inspression_rotate_book_img_meme_nft {
  width: 195px;
  height: 179.74px;
}

.inspression_rotate_book_btn {
  margin-top: 40px;
  width: 201px;
  height: 39px;
  cursor: pointer;
  background: rgba(101, 101, 101, 0.5);
  font-family: 'Avenir';
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inspression_rotate_book_btn:hover {
  background: #656565;
}

.inspression_drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 35px; */
}

.inspression_drop_desc {
  font-family: 'Avenir';
  font-size: 15px;
  /* line-height: 35px; */
  text-align: center;
  font-weight: 800;
  color: #4b4b4b;
  margin-bottom: 10px;
  /* margin-top: -13px; */
}

.inspression_drop_images {
  display: flex;
  flex-direction: row;
  /* margin-top: 15px; */
}

.inspression_drop_images_normal_img {
  width: 106px;
  height: 106px;
  cursor: pointer;
}

.inspression_drop_meme {
  width: 106px;
  height: 106px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspression_drop_meme p {
  font-family: 'Sweet Home Oklahoma';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: center;
  word-wrap: normal;
}

.inspression_drop_meme img {
  width: 66px;
  height: 66px;
}

.inspression_drop_images_no_right_border {
  border: #bebdbd solid 1px;
  border-right: none;
}

.inspression_drop_images_normal_border {
  border: #bebdbd solid 1px;
}

.inspression_drop_box {
  width: 429px;
  height: 163px;
  border: 1px solid #bebdbd;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
  justify-content: center;
}

.inspression_drop_box_img {
  width: 106px;
  height: 106px;
  border: 1px solid #bebdbd;
  margin-left: 30px;
}

.inspression_drop_box_text {
  display: flex;
  flex-direction: column;
  gap: 37px;
  margin-top: 10px;
  width: 301px;
}

.inspression_drop_box_text_nft_no {
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  font-family: 'Avenir';
  width: 255px;
  text-align: center;
  margin-bottom: 0;
  margin-left: 15px;
}

.inspression_drop_box_text_nft_no input {
  border: 1px solid #bebdbd;
  width: 95px;
  margin-left: 5px;
}

.inspression_drop_box_text_nft_no input:focus {
  outline: none;
}

.inspression_drop_box_text_desc {
  font-family: 'Avenir';
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #4b4a4a;
  width: 251px;
  height: 24px;
  margin-bottom: 0;
  margin-left: 16px;
}

.inspression_drop_input {
  margin-top: 15px;
  width: 429px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #bebdbd;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: black;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s linear, opacity 1s ease-in;
  padding-left: 10px;
}

.inspression_drop_input:focus {
  outline: none;
}

.inspression_drop_buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 26px;
}

.inspression_drop_buttons div {
  width: 201px;
  height: 39px;
  font-family: 'Avenir';
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(101, 101, 101, 0.5);
  gap: 20px;
}

.inspression_drop_buttons_connect:hover {
  background: #656565;
}

.inspression_drop_buttons_generate_loader {
  margin-right: -30px;
}

/* .inspression_drop_buttons_generate_loader:hover {
  background-color: red;
} */

.inspression_tile {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}

.inspression_tile_photos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspression_tile_photos img {
  width: 145.86px;
  height: 145.86px;
}

.inspression_tile_photos_normal {
  border: #bebdbd solid 1px;
  cursor: pointer;
}

.inspression_tile_photos_r1 {
  display: flex;
  flex-direction: row;
}

.inspression_tile_photos_no_right_border {
  width: 145.86px;
  height: 145.86px;
  border: #bebdbd solid 1px;
  border-right: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.inspression_tile_photos_no_right_top_border {
  border: #bebdbd solid 1px;
  border-right: none;
  border-top: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 145.86px;
  height: 145.86px;
  cursor: pointer;
}

.inspression_tile_photos_no_right_top_border p {
  font-family: 'Sweet Home Oklahoma';
  font-weight: 400;
  font-size: 30px;
  line-height: 20px;
  height: 100%;
  word-wrap: normal;
  color: #646161;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.inspression_tile_photos_no_right_top_border img {
  margin-top: 13px;
  width: 111px;
  height: 95px;
}

.inspression_tile_photos_no_top_border {
  border: #bebdbd solid 1px;
  border-top: none;
  cursor: pointer;
}

.inspression_tile_btn {
  margin-top: 75px;
  width: 201px;
  height: 39px;
  cursor: pointer;
  background: rgba(101, 101, 101, 0.5);
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.inspression_tile_btn:hover {
  background: #817f7f;
}

.inspression_tile_pop {
  margin-right: 560px;
  margin-top: 240px;
  margin-left: 10px;
  border: #bebdbd solid 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 229px;
  height: 229px;
}

.inspression_tile_pop p {
  font-family: 'Sweet Home Oklahoma';
  font-weight: 400;
  line-height: 40px;
  font-size: 40px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  height: 100%;
  word-wrap: normal;
}

.inspression_tile_pop_meme {
  width: 200px;
  height: 170px;
  /* margin-top: -20px; */
}

.inspression_tile_pop_img {
  width: 227px;
  height: 227px;
}

.inspression_rotate_pop {
  margin-top: -190px;
  margin-right: 550px;
}

.inspression_rotate_pop img {
  width: 280.03px;
  height: 280px;
}

.inspression_how {
  margin-top: 53px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  width: fit-content;
}

.inspression_how a {
  color: rgba(0, 0, 0, 0.3);
  text-decoration-line: underline;
}

.inspression_how:hover a {
  color: #000000;
  text-decoration-line: underline;
}

.inspression_how_div {
  width: fit-content;
}

.inspression_nft_empty {
  margin-top: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.inspression_nft_empty p {
  font-family: 'Avenir';
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.inspression_nft_empty_buy {
  width: 201px;
  height: 39px;
  left: 611px;
  top: 798px;
  background: rgba(101, 101, 101, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: 'Avenir';
  font-size: 13px;
  line-height: 13px;
  cursor: pointer;
}

.inspression_nft_empty_buy:hover {
  background: #656565;
}

.inspression_nft-list {
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24.46px;
  width: 1097.4px;
  margin-right: auto;
  margin-left: auto;
}

.inspression_not_nft_pop {
  box-sizing: border-box;
  width: 289px;
  height: 197px;
  background: #e2e2e2;
  border: 0.906158px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspression_not_nft_pop img {
  margin-top: -40px;
}

.inspression_not_nft_pop_p1 {
  margin-top: 5px;
  font-family: 'Avenir';
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.inspression_not_nft_pop_p2 {
  font-family: 'Avenir';
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.inspression_not_nft_pop_btn {
  width: 257px;
  height: 38px;
  background: #474747;
  font-family: 'Avenir';
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.inspression_review_minted_pop {
  width: 289px;
  height: 249px;
  background: #e2e2e2;
  border: 0.906158px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspression_review_minted_pop img {
  margin-top: -55px;
}

.inspression_review_minted_pop p {
  margin-top: 25px;
  font-family: 'Avenir';
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.inspression_review_minted_pop_opensea_btn {
  margin-top: 40px;
  width: 257px;
  height: 38px;
  background: #474747;
  font-family: 'Avenir';
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.inspression_review_minted_pop_back_btn {
  cursor: pointer;
  margin-top: 11px;
  width: 257px;
  height: 38px;
  background: #474747;
  font-family: 'Avenir';
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.inspression_general_pop_text {
  margin-top: 5px;
  text-align: center;
  font-family: 'Avenir';
  font-size: 15px;
  height: 36px;
  color: #000000;
}

.inspression_general_pop_back_btn {
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 0;
  width: 257px;
  height: 38px;
  background: #474747;
  font-family: 'Avenir';
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
