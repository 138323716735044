.about-page {
  /* background-image: url('./../Assets/Images/paper_Background.png'); */
  background-size: cover;
  position: relative;
  isolation: isolate;
  height: var(--bg-height);
  width: 100%;
}

.about-page::after {
  content: '';
  background-image: url('./../Assets/Gif/Background_gif.gif');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
  opacity: 0.05;
  height: var(--bg-height);
}

.about-page__about {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  height: 150px;
}

.about-page__man-blink {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
}

.about-page__what-sorekt {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 180px;
  height: 150px;
}

.about-page__explain {
  text-align: center;
  font-weight: medium;
  opacity: 0.8;
  margin-top: -5px;
  line-height: 30px;
}

.about-page__video {
  text-align: center;
  margin-top: 250px;
  position: relative;
  overflow: hidden;
}

.about-page__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 40px;
  right: 10px;
  margin: auto;
  border: 3px solid white;
}
.about-page__video__img {
  object-fit: cover;
  height: 520px;
}
